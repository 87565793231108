export default {
    title: 'Providers',
    addProvider: 'Add provider',
    choice: 'Pick your choice',
    idpInfo: 'IDP integration details',
    configTitle: 'IDP provider configuration',
    item: 'provider | providers',
    text: 'We currently support Azure, Okta, and Google IDP providers.',
    subtitle: 'SSO provider',
    error: 'Unable to retrieve integration details. Please try again.',
    callBackUrlAcs: {
        default: 'Callback URL (ACS)',
        azure: 'Reply URL (Assertion Consumer Service URL)',
        okta: 'Single sign-on URL',
        google: 'ACS URL',
    },
    issuer: {
        default: 'Issuer',
        azure: 'Identifier (Entity ID)',
        okta: 'Audience URI (SP Entity ID)',
        google: 'Entity ID',
    },
    metadataURL: {
        default: 'Metadata URL',
    },
    showAddDialog: {
        title: 'Add new SSO provider',
    },
    showEditDialog: {
        title: 'Edit {0} provider',
    },
    deleteDialog: {
        title: 'Delete provider',
        text: {
            p1: 'Are you sure you want to delete',
            p2: 'provider for this team?',
            p3: 'Users using SSO cannot able to login.',
        },
    },
    viewDialog: {
        title: 'View {0} provider',
        idpInfo: 'Integration details',
        configTitle: 'Configurations',
    },
    noData: 'You have not added any IDP providers',
    matchToDelete: 'Delete {0}',
    sso: {
        title: 'SAML (SSO)',
        dialog: {
            enable: {
                title: 'Enable SSO',
                body: 'This action will allow your team members to access the team using SSO.',
            },
            disable: {
                title: 'Disable SSO',
                body: 'This action will remove access to your team via SSO.',
            },
        },
        ssoEnabled: 'SSO Enabled',
    },
    error_add_409: 'Provider is in use, please set a different one.',
    error_update_409: 'Provider could not be updated, please try again.',
    error_default: 'There was an error, please try again.',
    certificateHelpText: 'Used to validate the signatures of incoming SAML responses',
    logoutUrlHelpText: 'If not set, defaults to entry point',
    issuerHelpText: 'A unique identifier for your application registered in your provider directory',
    infoHint: 'The following values will likely be required by your SAML v2 Identity Provider in order to trust Mambo.',
    idpTriggerTypeOptions: {
        self: 'Mambo Initiated',
        selfHelp: 'This option will display an SSO option on Mambo’s login screen, allowing users to authenticate using their IDP.',
        idp: 'IDP Initiated',
        idpHelp:
            'This option allows users to open the Mambo application directly from the IDP’s application console. In this case, the SSO option will not be displayed on Mambo’s login screen.',
    },
    idpTriggerType: {
        self: 'Mambo',
        idp: 'IDP',
    },
};
