export default {
    permissionGrants: 'Override individual permission requests',
    permissionGrantsItem: 'override | overrides',
    appsList: 'Application-specific policies',
    searchFields: 'name or package',
    addApplication: 'Add application',
    playStoreDown: 'Google Play Store servers seem to be down, try later to add more applications.',
    thisAppHasNoManagedConfiguration: 'This app has no managed configuration.',
    itemPermissionGrants: 'Grant permissions',
    itemPermissionGrantsItem: 'permission | permissions',
    noData: 'You have not added any apps',
    personalAppsList: 'Applied to applications on the personal profile',
    packageInUse: 'This package is already part of the list',
    credentialProviderPolicyDefaultHelp: 'Controls which apps are allowed to act as credential providers on Android 14 and above.',
    deviceInsightsHelperText: 'It captures Memory Usage Stats, Battery Usage Stats, Storage Usage Stats and Network Data Usage Stats',
    deviceInsightsTimeIntervalHelperText: 'Interval in which the device will send insights',
    credentialProviderPolicyHelp: 'Whether the app is allowed to act as a credential provider on Android 14 and above.',
    installPriorityHelp:
        'Controls the relative priority of installation. A value of 0 (default) means this app has no priority over other apps. For values between 1 and 10,000, a lower value means a higher priority.',
    itemInstallConstraintItem: 'install constraint | install constraints',
    installConstraintHelp: 'Defines a set of restrictions for the app installation.',
    managedConfigDialog: {
        title: 'Managed configuration',
    },
    delegatedScopesAlert: {
        p1: 'Network activity and security logs are restricted delegated scopes in use by our system, setting them may cause policy deployment to fail.',
        p2: 'Please contact us via our',
        // support page
        p3: 'if you want to use any of these.',
    },
    label: {
        playStoreMode: 'Default policy for Google Play Store',
        appAutoUpdatePolicy: 'Default policy for app auto updates',
        defaultPermissionPolicy: 'How to handle app permission requests',
        personalUsagePolicies: 'Personal usage policies',
        personalUsagePoliciesPersonalPlayStoreMode: 'Control how apps in the personal profile are handled',
        alwaysOnVpnLockdownExemption: {
            label: 'Exempt from always-on VPN connection',
            help: 'If exempted, the app will be allowed to use regular networking even if the VPN is unavailable',
        },
        workProfileWidgets: 'Home screen widgets permissions',
        credentialProviderPolicyDefault: 'Credential provider policy',
        credentialProviderPolicy: 'Credential provider policy',
    },
    select: {
        playStoreMode: {
            unspecified: 'Default',
            whitelist: 'Only whitelisted apps',
            blacklist: 'Block blacklisted apps',
        },
        appAutoUpdatePolicy: {
            unspecified: 'Default',
            always: 'Always',
            never: 'Never',
            choiceToTheUser: 'Choice to the user',
            wifiOnly: 'Wi-Fi only',
        },
        personalPlayStoreMode: {
            unspecified: 'Default',
            allowlist: 'Only whitelisted apps',
            blocklist: 'Block blacklisted apps',
        },
        defaultPermissionPolicy: {
            unspecified: 'Default',
            prompt: 'Prompt device user',
            grant: 'Always grant permissions',
            deny: 'Always deny permissions',
        },
        installType: {
            unspecified: 'Default',
            preinstalled: 'Pre-installed',
            forceInstalled: 'Force installed',
            blocked: 'Blocked',
            available: 'Available',
            requiredForSetup: 'Required for setup',
            kiosk: 'Kiosk',
        },
        delegatedScopes: {
            unspecified: 'Default',
            certInstall: 'Cert install',
            managedConfigurations: 'Managed configurations',
            blockUninstall: 'Block uninstall',
            permissionGrant: 'Permission grant',
            packageAccess: 'Package access',
            enableSystemApp: 'Enable system app',
            networkActivityLogs: 'Enable network activity logs',
            securityLogs: 'Enable security logs',
        },
        connectedWorkAndPersonalApp: {
            unspecified: 'Default',
            disallowed: 'Disallowed',
            allowed: 'Allowed',
        },
        permissionPolicy: {
            unspecified: 'Default',
            prompt: 'Prompt',
            grant: 'Grant',
            deny: 'Deny',
        },
        autoUpdateMode: {
            unspecified: 'Default',
            postponed: 'Postponed',
            highPriority: 'High Priority',
        },
        personalInstallType: {
            unspecified: 'Default',
            blocked: 'Blocked',
            available: 'Available',
        },
        alwaysOnVpnLockdownExemption: {
            unspecified: 'Default',
            enforced: 'Enforce VPN',
            exemption: 'Exempt from VPN',
        },
        workProfileWidgets: {
            unspecified: 'Default',
            allowed: 'Allowed',
            disallowed: 'Disallowed',
        },
        credentialProviderPolicyDefault: {
            unspecified: 'Default (disallowed)',
            disallowed: 'Disallowed when Credential Provider Policy of the app is unspecified',
            disallowed_except_system: 'Disallowed except OEM default credential providers',
        },
        credentialProviderPolicy: {
            unspecified: 'Default',
            allowed: 'Allowed',
        },
        networkTypeConstraint: {
            unspecified: 'Default (Any active networks)',
            any: 'Any active networks (Wi-Fi, cellular, etc.)',
            unmetered: 'Any unmetered network (e.g. Wi-FI)',
        },
        chargingConstraint: {
            unspecified: "Default (Device doesn't have to be charging)",
            notRequired: "Device doesn't have to be charging",
            whenCharging: 'Device has to be charging',
        },
        deviceIdleConstraint: {
            unspecified: "Default (doesn't have to be idle)",
            notRequired: "Device doesn't have to be idle, app can be installed while the user is interacting with the device",
            whenIdle: 'Device has to be idle',
        },
    },
    installTypeWarning: {
        kioskInUse: "The 'Kiosk' install type option is already used by another app ({appName}), so the option has been removed for this app.",
        requiredForSetupLimit:
            "The 'Required for setup' install type option is already used on four other apps, so the option has been removed for this one due to AMAPI limits on this install type.",
    },
    contentManager: {
        title: 'Content manager libraries',
        addAction: 'Add Library',
        name: 'Name',
        namePlaceholder: 'Library name',
        type: 'Type',
        typePlaceholder: 'Media / Document',
        add: 'Add',
        additionalSettingTitle: 'Additional content manager settings',
        openDefaults: 'Open defaults',
        selectLibrary: 'Select library',
        bottomNavigation: 'Additional bottom navigation',
        configure: 'Configure',
        remove: 'Remove',
        configurations: 'Configurations',
        selectExistentFiles: 'Select existent files',
        selectFilesPlaceholder: 'Select files',
        orAddNewOnes: 'Or add new ones',
        layout: 'Layout',
        autoplay: 'Autoplay next',
        loop: 'Loop',
        select: {
            type: {
                images: 'Images',
                videos: 'Videos',
                files: 'Files',
            },
            openDefaults: {
                open: 'Library Open',
                forced: 'Library Forced',
                no: 'No Library',
            },
            bottomNavigation: {
                images: 'Images',
                videos: 'Videos',
                files: 'Others',
                documents: 'Documents',
            },
            layout: {
                list: 'List',
                gallery: 'Gallery',
                carousel: 'Carousel',
                grid: 'Grid',
            },
        },
        dialogConfirmButtonText: 'Ok',
    },
    managedLauncherBeta:
        'Our managed launcher is currently in beta, if you encounter any issues please feel free to report them via support or our feedback form.',
    userControlSettings: {
        title: 'User control',
        help: 'includes user actions like force-stopping and clearing app data',
        unspecified: 'Default',
        allowed: 'Allowed',
        disallowed: 'Disallowed',
    },
    deviceInsightsInterval: {
        every_hour: 'Every hour',
        every_4_hours: 'Every 4 hours',
        every_8_hours: 'Every 8 hours',
        every_12_hours: 'Every 12 hours',
        every_day: 'Every day',
    },
    apkFileSelect: 'Select Android Package (.apk)',
};
