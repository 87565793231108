import { MutationTree, ActionTree, GetterTree } from 'vuex';
import Vue from 'vue';
import decode from 'jwt-decode';
import { AxiosAuthRefreshRequestConfig } from 'axios-auth-refresh';
import Cookies from 'js-cookie';
import Axios from '@/plugins/axios';
import router from '@/router';
import { IUsers } from '@/shared/types';
import { initialURLkey } from '@/util/impersonate-initial-url-cookie-key';

export interface Tokens {
    token: string;
    refresh: string;
    twoFactorToken?: string;
}

export type AuthState = {
    isAuthenticated: boolean;
    tokens?: Tokens;
    tokenWatcherInterval?: NodeJS.Timer;
};

export const namespaced = true;

export const state = (): AuthState => ({
    isAuthenticated: false,
    tokens: {
        token: '',
        refresh: '',
    },
    tokenWatcherInterval: undefined,
});

export const mutations: MutationTree<AuthState> = {
    setAuthentication(state: AuthState, tokens: Tokens) {
        const currentTokens = state.tokens;
        Vue.set(state, 'tokens', { ...currentTokens, ...tokens });
        state.isAuthenticated = true;
    },
    removeAuthentication(state: AuthState) {
        Vue.set(state, 'tokens', { token: '', refresh: '' });
        state.isAuthenticated = false;
    },
    set2FA(state: AuthState, twoFactorToken: string) {
        Vue.set(state, 'tokens', { twoFactorToken });
    },
    setTokenWatcher(state: AuthState, tokenWatcherKey: NodeJS.Timer) {
        if (!state.tokenWatcherInterval) {
            Vue.set(state, 'tokenWatcherInterval', tokenWatcherKey);
        }
    },
};

export const actions: ActionTree<AuthState, any> = {
    setAuthTokens({ commit, dispatch }, tokens: any) {
        if (tokens.twoFactorToken) {
            commit('set2FA', tokens.twoFactorToken);
            return '2fa';
        }
        Axios.defaults.headers.common.Authorization = `Bearer ${tokens.token}`;
        commit('setAuthentication', tokens);

        // Init WebSockets connection
        dispatch('socket/init', null, { root: true });
    },
    async loginWithTokens({ commit, dispatch }, tokens) {
        if (tokens.twoFactorToken) {
            commit('set2FA', tokens.twoFactorToken);
            return '2fa';
        }
        Axios.defaults.headers.common.Authorization = `Bearer ${tokens.token}`;
        commit('setAuthentication', tokens);

        // Update team and user membership checks
        await dispatch('team/setTeam', null, { root: true });

        // Check if user is member of current team
        await dispatch('team/checkIsMember', null, { root: true });

        // Init WebSockets connection
        await dispatch('socket/init', null, { root: true });
    },
    safeRefresh({ state, dispatch }) {
        const { token = null } = state.tokens as Tokens;
        if (!token) return;
        console.log('Safe refresh: Token expires at', new Date(decode<{ exp: number }>(token).exp * 1000));
        if (decode<{ exp: number }>(token).exp * 1000 <= new Date().getTime()) {
            console.log('Safe refreshing...');
            return dispatch('refresh');
        }
    },
    async refresh({ state, dispatch, commit }) {
        console.log('Starting auth/refresh action');

        // Get tokens
        const { refresh: refreshToken = null } = state.tokens as Tokens;

        // If no token or already refreshing, cancel action
        if (!refreshToken) {
            console.error('Missing refresh token');

            await dispatch('logout');
            return 'auth/refresh action: User logged out';
        }

        // Try to refresh token
        try {
            const { data: tokens } = await Axios.post<Tokens>('/auth/refresh', { token: refreshToken }, {
                skipAuthRefresh: true,
            } as AxiosAuthRefreshRequestConfig);

            console.log('Completed `POST /auth/refresh` in auth/refresh action');

            // Set token for axios
            Axios.defaults.headers.common.Authorization = `Bearer ${tokens.token}`;

            // Set token and refreshing false in store
            commit('setAuthentication', tokens);

            // Init WebSockets connection
            await dispatch('socket/init', null, { root: true });

            await dispatch('socket/updateToken', tokens.token, { root: true });

            // Return token
            return tokens.token;
        } catch (e) {
            console.error('Error occured in auth/refresh action', e);

            // Logout user after failed refresh
            await dispatch('logout');
            return 'auth/refresh action: User logged out';
        }
    },
    async logout({ commit, dispatch }) {
        console.log('Starting auth/logout action');

        // Close WebSockets connection
        dispatch('socket/close', null, { root: true });

        // `POST` logout to remove user session
        await Axios.post('/auth/logout', null, {
            skipAuthRefresh: true,
        } as AxiosAuthRefreshRequestConfig).catch((e) => console.warn("Couldn't remove user session.", e));

        console.log('Completed `POST /auth/logout` in auth/logout action');

        // Remove user details
        commit('removeAuthentication');
        commit('user/setUserDetails', { details: {}, memberships: [] }, { root: true });

        // Redirect user
        return router.push('/auth/login');
    },
    async impersonateUser({ dispatch, rootGetters }, user: IUsers) {
        // Fetch the impersonate tokens
        const { data } = await Axios.post<{
            token: string;
            refresh: string;
        }>(`/resellers/${user.resellerId}/users/${user.id}/impersonate`);

        // Consume token
        await dispatch('setAuthTokens', data);

        // Set the current path in cookie, so that we return
        // to the current page after stopping impersonation
        Cookies.set(initialURLkey, window.location.pathname, {
            domain: rootGetters['reseller/domain'],
        });

        // Redirect to dashboard
        window.location.href = `https://dashboard.${user.reseller?.domain}/`;
    },
    setTokenWatcher({ commit, state, dispatch }) {
        // Set watcher only once
        if (!state.tokenWatcherInterval) {
            commit(
                'setTokenWatcher',
                // Check for token refresh every minute
                setInterval(() => {
                    dispatch('safeRefresh');
                }, 60 * 1000),
            );
        }
    },
};

export const getters: GetterTree<AuthState, any> = {
    isAuthenticated: (state) => state.isAuthenticated,
    isImpersonated: (state, getters, rootState, rootGetters) => {
        if (rootState.exitingImpersonate) return true;

        // Check if impersonated
        const token = state.tokens?.token;

        if (token) {
            const parsed = decode<Record<string, unknown>>(token);
            // check if this is an impersonation token
            return parsed.id !== parsed.sub && parsed.sub === rootGetters['user/details']?.id;
        }

        return false;
    },
    accessToken: (state) => state.tokens?.token,
};
