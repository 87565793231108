export default {
    sendLoginLink: 'Send a login link',
    continue: 'Continue',
    loginToAccount: 'Login to your account',
    sendResetLink: 'Send reset link',
    updatePassword: 'Update password',
    sendSignupLink: 'Send a sign up link',
    resend: 'Resend',
    resent: 'Resent',
    goToDashboard: 'Go to dashboard',
    openBillingPortal: 'Open billing portal',
    actions: 'Actions',
    generateReport: 'Generate report',
    extendTrialPeriod: 'Extend Trail Period',
    copy: 'Copy',
    cancel: 'Cancel',
    cancelChange: 'Cancel change',
    noDontDelete: "No, don't delete",
    noDontChange: "No, don't change",
    downloadBasicCsvFile: 'Download basic CSV file',
    actionsOnDevices: 'Actions on devices',
    downloadTeamData: 'Download team data',
    deleteTeam: 'Delete team',
    cancelDelete: 'Cancel Deletion',
    updateEnterprise: 'Update Enterprise',
    noDontRemove: "No, don't remove",
    updateTeamSettings: 'Update team settings',
    goBack: 'Go back',
    addOwner: 'Add owner',
    complete: 'Complete',
    yesIveCopiedThem: "Yes, I've copied them",
    copyBackupCodes: 'Copy backup codes',
    downloadYourData: 'Download your data',
    deleteYourAccount: 'Delete your account',
    changePassword: 'Change password',
    disable2fa: 'Disable 2FA',
    regenerateBackupCodes: 'Regenerate backup codes',
    noDontRegenerate: "No, don't regenerate",
    updateSettings: 'Update settings',
    noDontLogout: "No, don't logout",
    noDontLeave: "No, don't leave",
    back: 'Back',
    seeAll: 'See all',
    signOut: 'Sign out',
    changeTeam: 'Change team',
    createNewTeam: 'Create new team',
    seeAllYourTeams: 'See all your teams',
    selectTeam: 'Select team',
    playStore: 'Play store',
    packageName: 'Package name',
    privatePackages: 'Private application',
    selectAll: 'Select all',
    changeComponent: 'Change component',
    copyZeroTouchNfc: 'Copy Zero touch / NFC data',
    downloadImage: 'Download image',
    refresh: 'Refresh',
    more: 'More',
    locateDevice: 'Locate device',
    pastLocations: 'Past locations',
    select: 'Select',
    filter: 'Filter',
    addField: 'Add field',
    enroll: 'Enroll',
    save: 'Save',
    close: 'Close',
    support: 'Support',
    downloadCsvBulkActions: 'Download bulk actions sample CSV',
    managedLauncher: 'Managed launcher',
    addGroup: 'Add Group',
};
