import Vue from 'vue';

// Add i18n
import i18n from '@/plugins/i18n';
// Add Vuetify
import vuetify from '@/plugins/vuetify';
// Add env
import '@/plugins/env';
// Add main app component with router and store
import App from './App.vue';
import router from './router';
import store from './store';
// Add global CSS
import '@/assets/app.scss';
// Add custom RxJS plugins
import '@/plugins/updates-subject';
import '@/plugins/permissions-subject';
// Add Vue metadata plugin
import '@/plugins/page-metadata';
// Add Vuex persistence
import '@/plugins/vuex-persist';
// Add VueFormulate
import '@/plugins/vue-formulate';
// Add Vue Swatches
import '@/plugins/vue-swatches';
// Add Axios and Axios plugins
import '@/plugins/axios';
import '@/plugins/axios-refresh';
import '@/plugins/axios-request-canceller';
import '@/plugins/axios-health-check';

const isDev = !['production', 'testing'].includes(process.env.NODE_ENV ?? '');
Vue.config.productionTip = isDev;
Vue.config.devtools = isDev;

const bootstrap = async () => {
    console.time('bootstrap');

    // Run store init function
    await store.dispatch('init');

    // Load Vue
    new Vue({
        router,
        store,
        vuetify,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        i18n,
        render: (h) => h(App),
    }).$mount('#app');

    console.timeEnd('bootstrap');
};

bootstrap();
