
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';
import { IResellers } from '@/shared/types';

@Component({
    computed: mapGetters({
        reseller: 'reseller/reseller',
        domain: 'reseller/domain',
    }),
})
export default class Default extends Vue {
    reseller!: IResellers | null;
    domain!: string;
}
