export enum ReportType {
    /** Basic PDF report */
    ASSETS_BASIC_PDF = 0,
    /** Detailed PDF report (All fields) */
    ASSETS_DETAILED_PDF,
    /** Basic CSV report */
    ASSETS_BASIC_CSV, // devices export as csv with selected columns and filters
    TEAM_AND_BILLING, // Reseller Teams + Billing Info report
    APP_VERSION_REPORT, // App version CSV report
}

export enum ReportStatus {
    QUEUED = 'QUEUED',
    INPROGRESS = 'INPROGRESS',
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS',
}
